import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  /* CSS reset */
  /* Recommendations from https://www.joshwcomeau.com/css/custom-css-reset/#the-css-reset */
  /* (Plus some of our own edits) */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
  }

  html,
  body {
    height: 100%;
  }

  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    font-family: Gilroy, sans-serif;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.typePrimary};
  }

  img,
  picture,
  video,
  canvas {
    display: block;
    max-width: 100%;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  a {
    text-decoration: none;
    line-height: 1.25;
  }

  button {
    /* iOS adds padding to buttons when there is an image as a child,
    which can mess with spacing or stretch a circle into an oval. */
    padding: 0;
    line-height: 1.25;
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.25;
  }

  /* Global styles */
  @font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-RegularItalic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-ExtraBold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-ExtraBoldItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'Blacker';
    src: url('/fonts/Blacker-Display-Heavy.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Blacker';
    src: url('/fonts/Blacker-Display-HeavyItalic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
  }

  ::selection {
    color: ${({ theme }) => theme.colors.typePrimary};
    background: ${({ theme }) => theme.colors.actionPrimary};
  }

  mark {
    color: ${({ theme }) => theme.colors.typePrimary};
    background: ${({ theme }) => theme.colors.actionPrimary};
    padding: 0.25rem;

    &::selection {
      color: ${({ theme }) => theme.colors.typeDarkPrimary};
      background: ${({ theme }) => theme.colors.architecture8};
    }
  }
`
